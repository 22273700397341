<template>
  <div class="collectList_container">
    <div class="collectList_banner">
      <img
        class="collectList_cover"
        :src="require('@/assets/img/art_banner.png')"
      />
      <div class="collectList_search_input">
        <el-input
          v-model.trim="search.key_word"
          placeholder="请输入关键词"
          @keyup.enter="searchData"
          @blur="handlerBlur"
        >
          <template #suffix>
            <el-icon class="el-icon-search" @click="searchData"
              ><Search
            /></el-icon>
          </template>
        </el-input>
      </div>
      <div class="collectList_select">
        <el-select
          v-model.trim="search.periods"
          placeholder="Select"
          popper-class="select_option"
          @change="changePeriods"
        >
          <el-option label="全部" value="0" />
          <el-option
            v-for="item in periodsList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="collectList_conent">
      <listScroll
        ref="listScrollRef"
        :url="$urls.literature.collectList"
        :select="search"
      >
        <template v-slot:listItem="{ cItems, cIndex }">
          <articleListItem
            :key="cIndex"
            :item="cItems"
            :index="cIndex"
            type="collectList"
            @click="handleDetail(cItems)"
          ></articleListItem>
        </template>
      </listScroll>

      <div class="collectList_foot_group">
        <CustomButton
          style="padding: 0; height: 30px; width: 100px"
          size="small"
          @click="goToBack"
          >返回主页
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import ListScroll from "@/components/unit/ListScroll";
import articleListItem from "./articleListItem.vue";
import CustomButton from "@/components/unit/CustomButton";
import { Search } from "@element-plus/icons";
import wx from "@/common/weixin";
import weixin from "weixin-js-sdk";
export default {
  name: "collectList",
  components: {
    ListScroll,
    articleListItem,
    CustomButton,
    Search,
  },
  data() {
    return {
      data: [],
      timer: null,
      rotate: false,
      periodsList: [],
      search: { key_word: "", periods: "0", page: 1, type: 0 },
      userInfo: {},
      saleInfo: {},
    };
  },
  created() {
    this.saleInfo = this.$tools.getStorage("saleLogin", localStorage);
    this.userInfo = this.$tools.getStorage("userLogin", localStorage);
    this.search.type = this.$store.state.common.userInfo.id
      ? 0
      : this.$store.state.common.salesInfo.phone
      ? 1
      : 0;
    this.getPeriodData();
  },
  mounted() {
    setTimeout(() => {
      this.initShare();
    }, 1000);
  },
  methods: {
    initShare() {
      let params = {
        title: this.$config.title,
        description: "",
        url: "",
        logo: this.$config.logo,
      };
      console.log("params :>> ", params);
      weixin.ready(() => {
        wx.sharePageMessage(params);
      });
    },
    searchData(status = true) {
      this.search.page = 0;
      if (status) {
        this.search.periods = "0";
      }
      this.$refs.listScrollRef.searchData(this.search);
    },
    handlerBlur() {
      this.search.periods = "0";
      this.searchData();
    },
    changePeriods() {
      this.search.key_word = "";
      this.searchData(false);
    },
    handleDetail(item) {
      // this.$refs.listScrollRef.saveCurrDate();
      // this.$tools.setStorage("search_data", this.search);
      if (item.tag != "幻灯") {
        if (!item.is_jump) {
          this.$router.push({
            path: "/articleDetail",
            query: {
              id: item.id,
            },
          });
        } else {
          this.$router.push({
            path: "/customArticleDetail",
            query: {
              id: item.id,
              projectId: this.$config.project,
            },
          });
        }
      } else {
        // //有登陆 双身份 有限专家
        // if (this.userInfo?.phone) {
        //   this.$router.push({
        //     path: "/ppt/detail",
        //     query: {
        //       id: item.id,
        //     },
        //   });
        // } else
        if (this.saleInfo?.phone || this.userInfo?.phone) {
          //销售  专家
          this.$router.push({
            path: "/courseware/preview",
            // path: "/courseware/detail",
            query: {
              id: item.id,
            },
          });
        } else {
          //没有登陆
          this.$router.push({
            path: "/ppt/detail",
            query: {
              id: item.id,
            },
          });
        }
      }
    },
    getPeriodData() {
      this.$axios
        .get(this.$urls.literature.periodsList)
        .then((res) => {
          this.periodsList = res.data.map((num) => {
            let year = num.split("-")[0];
            let month = parseInt(num.split("-")[1]);
            return {
              value: num,
              label: `${year}-第${month}期`,
              // label: `${year}-第${this.$tools.intToChinese(month)}期`,
            };
          });
        })
        .catch(() => {});
    },
    goToBack() {
      this.$router.push({
        path: "/articleList",
        query: { is_sale: this.$route.query.is_sale },
      });
    },
  },
};
</script>

<style >
.collectList_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.collectList_banner {
  position: relative;
}
.collectList_cover {
  width: 100%;
  height: auto;
}
.collectList_banner .collectList_search_input {
  width: 100%;
  height: 30px;
  padding: 0 20px;
  position: absolute;
  bottom: 170px;
}
.collectList_banner .collectList_search_input .el-input {
  width: 100%;
  height: 30px;
  line-height: normal !important;
}
.collectList_search_input input {
  height: 30px;
  line-height: normal !important;
  font-size: 14px !important;
  border-radius: 2rem;
}
.collectList_search_input input::-webkit-input-placeholder {
  line-height: normal !important;
  font-size: 14px !important;
  color: #4b4b4b;
}
.collectList_banner .collectList_search_input .el-input__suffix {
  font-size: initial;
  display: flex;
  align-items: center;
  right: 20px;
  color: #4b4b4b;
}

.collectList_banner .el-input__wrapper {
  border: 1px solid #4b4b4b;
  background: border-box;
  color: #4b4b4b;
  border-radius: 18px;
  box-shadow: none !important;
}

.collectList_banner .collectList_select .el-input__inner {
  border: none !important;
}
.collectList_select {
  position: absolute;
  right: 23px;
  width: 130px !important;
  bottom: 110px;
}
.collectList_select .el-select {
  width: 130px !important;
  border-bottom: 1px solid #f26649;
}
.el-select .el-input .el-select__caret,
.collectList_select .el-input__inner,
.select_option .el-select-dropdown__item.selected {
  color: #f26649 !important;
}
.collectList_conent {
  background: #fff;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  padding: 0 23px;
  margin-top: 10px;
  margin-top: -85px;
}
.collectList_foot_group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.collectList_foot_icon {
  width: 34px;
  height: 34px;
}
.collectList_foot_icon_small {
  width: 25px;
  height: auto;
}
.refresh_anim {
  animation: loop 0.5s linear 0.2s infinite;
}

@keyframes loop {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.collectList_container .el-select__wrapper {
  border: none;
  box-shadow: none !important;
}
.collectList_banner .collectList_search_input .el-input__inner {
  padding-left: 2px;
}
</style>